// bootstrap stuff

// npm install -D sass
// npm install  bootstrap
// then the react tool chain will atumatically create the css file when referencing this file as  import "./scss/custom.scss" 
// the__variables.scss was obtained using https://bootstrap.build/ (which contains only the changes made relative to the original 
// bootstrap default variables. This can be re-imported in the tool to continue making changes in this environment)
// to use only a needed subset of the full set (RECOMMENDED) see https://getbootstrap.com/docs/5.0/customize/sass/

@import "./variables.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";


.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: rgba(247, 247, 247, 0.05) !important;
}

// an form input with floating label is too high, make it a little lower
.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    //height: calc(3.5rem + 2px);
    height: calc(3.0rem + 2px);
    line-height: 1.25;
}

.table>th {
    //background-color: #dbe4f0 !important;
    background-color: #ecf0f6 !important;
    color: slategray;
}

.centeredSpinnerContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2000;
}

.autocompleteList {
    background-image: none;
    width: 200%;

    >ul {
        padding: 0;

        >li {
            padding: 0px;
            color: var(--bs-link-hover-color);
            background-color: var(--#{$prefix}table-hover-bg);
        }

        >li:hover {
            color: $table-active-color;
            background-color: #{$table-active-bg} //var(--#{$prefix}table-hover-bg)    //   #{$table-active-bg};
        }
    }
}

.stdIconClickable {
    @extend .link-primary;
}

// currently the bootstrap icons are positioned vertically slightly to allow, correct
.stdIcon>svg {
    @extend .fs-5;
    //vertical-align: -0.125em;
}

.stdTableOLD {
    >tbody {
        >tr {
            cursor: pointer;

            >td {
                >div.indexRowActions {
                    padding-top: 3px;
                    cursor: auto;
                }
            }
        }
    }
}

.stdTable {
    >tbody {
        tr {
            cursor: pointer;
        }
        td.indexRowActions {
            cursor: auto;
          }
    }
    .selectCheckbox{
        margin-top: 0.0rem;
    }
}